export const rooms = [
    {
        id: 1,
        title: "Chambre 1",
        capacity: 3,
        bathroom: "bain",
        bs_price: 98,
        ms_price: 108,
        hs_price: 118,
        bed: "1x1 + 1x2",
        terrace: false
    },
    {
        id: 2,
        title: "Chambre 2",
        capacity: 2,
        bathroom: "bain",
        bs_price: 83,
        ms_price: 93,
        hs_price: 108,
        bed: "1x2",
        terrace: false
    },
    {
        id: 3,
        title: "Chambre 3",
        capacity: 2,
        bathroom: "douche",
        bs_price: 90,
        ms_price: 98,
        hs_price: 108,
        bed: "1x2",
        terrace: true
    },
    {
        id: 4,
        title: "Chambre 4",
        capacity: 2,
        bathroom: "douche",
        bs_price: 90,
        ms_price: 98,
        hs_price: 112,
        bed: "1x2",
        terrace: true
    },
    {
        id: 5,
        title: "Chambre 5",
        capacity: 2,
        bathroom: "douche",
        bs_price: 83,
        ms_price: 93,
        hs_price: 108,
        bed: "1x2",
        terrace: false
    },
    {
        id: 6,
        title: "Chambre 6",
        capacity: 2,
        bathroom: "douche",
        bs_price: 83,
        ms_price: 93,
        hs_price: 108,
        bed: "1x2",
        terrace: false
    },
    {
        id: 7,
        title: "Chambre 7",
        capacity: 4,
        bathroom: "douche",
        bs_price: 98,
        ms_price: 108,
        hs_price: 118,
        bed: "2x2",
        terrace: false
    },
    {
        id: 8,
        title: "Chambre 8",
        capacity: 2,
        bathroom: "douche",
        bs_price: 83,
        ms_price: 93,
        hs_price: 108,
        bed: "1x2",
        terrace: false
    },
    {
        id: 9,
        title: "Chambre 9",
        capacity: 1,
        bathroom: "douche",
        bs_price: 75,
        ms_price: 85,
        hs_price: 97,
        bed: "1x1",
        terrace: false
    },
    {
        id: 10,
        title: "Suite 10",
        capacity: 5,
        bathroom: "bain",
        bs_price: 148,
        ms_price: 173,
        hs_price: 181,
        bed: "1x1 + 2x2",
        terrace: true
    },
    {
        id: 11,
        title: "Chambre 11",
        capacity: 2,
        bathroom: "bain",
        bs_price: 98,
        ms_price: 108,
        hs_price: 117,
        bed: "1x2",
        terrace: true
    },
    {
        id: 12,
        title: "Chambre 12 accès aux personnes en situation de handicap",
        capacity: 4,
        bathroom: "handicap",
        bs_price: 107,
        ms_price: 117,
        hs_price: 127,
        bed: "1x2 + 2x1",
        terrace: true
    },
    {
        id: 23,
        title: "Suite 23",
        capacity: 4,
        bathroom: "bain",
        bs_price: 148,
        ms_price: 173,
        hs_price: 181,
        bed: "2x2",
        terrace: true
    },
    {
        id: 24,
        title: "Chambre 24",
        capacity: 2,
        bathroom: "douche",
        bs_price: 83,
        ms_price: 93,
        hs_price: 108,
        bed: "1x2",
        terrace: false
    }
]

export const rooms_en = [
    {
        id: 1,
        title: "Room 1",
        capacity: 3,
        bathroom: "bath",
        bs_price: 98,
        ms_price: 108,
        hs_price: 118,
        bed: "1x1 + 1x2",
        terrace: false
    },
    {
        id: 2,
        title: "Room 2",
        capacity: 2,
        bathroom: "bath",
        bs_price: 83,
        ms_price: 93,
        hs_price: 108,
        bed: "1x2",
        terrace: false
    },
    {
        id: 3,
        title: "Room 3",
        capacity: 2,
        bathroom: "shower",
        bs_price: 90,
        ms_price: 98,
        hs_price: 112,
        bed: "1x2",
        terrace: true
    },
    {
        id: 4,
        title: "Room 4",
        capacity: 2,
        bathroom: "shower",
        bs_price: 90,
        ms_price: 98,
        hs_price: 108,
        bed: "1x2",
        terrace: true
    },
    {
        id: 5,
        title: "Room 5",
        capacity: 2,
        bathroom: "shower",
        bs_price: 83,
        ms_price: 93,
        hs_price: 108,
        bed: "1x2",
        terrace: false
    },
    {
        id: 6,
        title: "Room 6",
        capacity: 2,
        bathroom: "shower",
        bs_price: 83,
        ms_price: 93,
        hs_price: 108,
        bed: "1x2",
        terrace: false
    },
    {
        id: 7,
        title: "Room 7",
        capacity: 4,
        bathroom: "shower",
        bs_price: 98,
        ms_price: 108,
        hs_price: 118,
        bed: "2x2",
        terrace: false
    },
    {
        id: 8,
        title: "Room 8",
        capacity: 2,
        bathroom: "shower",
        bs_price: 83,
        ms_price: 93,
        hs_price: 108,
        bed: "1x2",
        terrace: false
    },
    {
        id: 9,
        title: "Room 9",
        capacity: 1,
        bathroom: "shower",
        bs_price: 75,
        ms_price: 85,
        hs_price: 97,
        bed: "1x1",
        terrace: false
    },
    {
        id: 10,
        title: "Suite 10",
        capacity: 5,
        bathroom: "bath",
        bs_price: 148,
        ms_price: 173,
        hs_price: 181,
        bed: "1x1 + 2x2",
        terrace: true
    },
    {
        id: 11,
        title: "Room 11",
        capacity: 2,
        bathroom: "bath",
        bs_price: 98,
        ms_price: 108,
        hs_price: 117,
        bed: "1x2",
        terrace: true
    },
    {
        id: 12,
        title: "Room 12 disabled access",
        capacity: 4,
        bathroom: "handicap",
        bs_price: 107,
        ms_price: 117,
        hs_price: 127,
        bed: "1x2 + 2x1",
        terrace: true
    },
    {
        id: 23,
        title: "Suite 23",
        capacity: 4,
        bathroom: "bath",
        bs_price: 148,
        ms_price: 173,
        hs_price: 181,
        bed: "2x2",
        terrace: true
    },
    {
        id: 24,
        title: "Room 24",
        capacity: 2,
        bathroom: "shower",
        bs_price: 83,
        ms_price: 93,
        hs_price: 108,
        bed: "1x2",
        terrace: false
    }
]